import revive_payload_client_C2Jsi9m76e from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_z326gazg5r5gx5t5qcnamj273a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_utnrVG4qjQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_z326gazg5r5gx5t5qcnamj273a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ID12QNfsjL from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_z326gazg5r5gx5t5qcnamj273a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ts4YIAKkJe from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_z326gazg5r5gx5t5qcnamj273a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6oJ5qk5VXN from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_z326gazg5r5gx5t5qcnamj273a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_duMATtUHPT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_z326gazg5r5gx5t5qcnamj273a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_eew0pcqlGV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_z326gazg5r5gx5t5qcnamj273a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_rPiE6knWuW from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_z326gazg5r5gx5t5qcnamj273a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vue_query_wrmMkNpEpe from "/opt/buildhome/repo/plugins/vue-query.ts";
export default [
  revive_payload_client_C2Jsi9m76e,
  unhead_utnrVG4qjQ,
  router_ID12QNfsjL,
  payload_client_ts4YIAKkJe,
  navigation_repaint_client_6oJ5qk5VXN,
  check_outdated_build_client_duMATtUHPT,
  chunk_reload_client_eew0pcqlGV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_rPiE6knWuW,
  vue_query_wrmMkNpEpe
]