import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as landingwissFWelQ7Meta } from "/opt/buildhome/repo/pages/landing.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "landing",
    path: "/landing",
    meta: landingwissFWelQ7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/landing.vue").then(m => m.default || m)
  }
]